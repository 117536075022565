
.swal2-container.swal2-center
    >.swal2-popup
        font-family: inherit
        font-size: 14px

        .swal2-html-container
            margin: 0
            padding: 0
            ul
                text-align: left
                max-width: 300px
                margin: 10px auto 5px

        .swal2-confirm
            // background: #555
            // border-radius: 20px
            // padding-left: 1em
            // padding-right: 1em
            min-width: 40%
        .swal2-actions
            margin: 0
            padding: 0


.success-poupup-form
    padding: 40px 75px !important
    width: 600px !important
    .swal2-title
        margin-top: 1.5rem !important
        margin-bottom: 3rem !important
    .swal2-html-container
        margin-bottom: 2rem !important

.no-border
    border: 0 !important
