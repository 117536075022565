
// .bg-green-btn
//   background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%)

// .bg-blue-btn
//   background: radial-gradient(91.66% 306.32% at 0% 16.18%, #019A9A 0%, #0B70A9 100%)
  
html, body
  margin: 0
  padding: 0
  *
    box-sizing: border-box

  .bg-white
    background-color: #fff
    *
      color: #000

    .icons--dot,
    .icons--dot:before
      background-color: #000

  .bg-g-green
    background: radial-gradient(182% 293.5% at -60% 26%, #00CF9E 0%, #0F8763 100%)

  .bg-b-green
    background: radial-gradient(100% 381.59% at 0% 6.83%, #00CF9E 0%, #009A9A 100%)

    .page-btn
      color: #000 !important
      background: #fff !important

    .steps-main__post--ico img
      filter: grayscale(1) brightness(10)

  .bg-green
    background: radial-gradient(122.72% 100% at 2.53% 0%, #52D2B4 0%, #11425E 100%)

  .bg-dark-blue
    background: radial-gradient(174.74% 125.51% at 11.35% 6.15%, #10487B 0%, #141D4D 100%)

  .bg-dark-blue2
    background: radial-gradient(152.77% 124.49% at 2.53% 0%, #0083CC 0%, #141D4D 100%)

  .bg-dark-blue3
    background: radial-gradient(139.37% 100% at 0% 0%, #00B7CF 0%, #141D4D 100%)

  .bg-blue
    background: radial-gradient(122.72% 100% at 2.53% 0%, #019A9A 0%, #0B70A9 100%)
    @media (min-width: 1024px)
      .header-content-nav__subchoose,
      .sub-menu
        background: radial-gradient(122.72% 100% at 2.53% 0%, #019A9A 0%, #0B70A9 100%)

  .header-green-line
    background: radial-gradient(10% 170.23% at 0.63% 0%, #00CF9E 0%, #009A9A 100%)

  .header-dark-blue-line
    background: linear-gradient(90.08deg, #0083CC 2.21%, #141D4D 100.01%)

  .header-green
    background: radial-gradient(10% 170.23% at 0.63% 0%, #00CF9E 0%, #009A9A 100%)
    background: radial-gradient(200px 100px at max(50% - 630px, 7vw) 0%, #00CF9E 0%, #009A9A 100%)
    @media (min-width: 1024px)
      .header-content-nav__subchoose,
      .sub-menu
        background: radial-gradient(38.04% 48.78% at -106.73% 0%, #00CF9E 0%, #009A9A 100%)

  .header-blue-line
    background: linear-gradient(270deg, #0083CC 68.62%, #019A9A 100%)

  .header-blue
    background: linear-gradient(270deg, #0083CC 68.62%, #019A9A 100%)
    background: radial-gradient(20% 170.23% at max(50% - 630px, 7vw) 0%, #019A9A 10%, #0083CC 100%)
    background: radial-gradient(200px 100px at max(50% - 630px, 7vw) 0%, #019A9A 10%, #0083CC 100%)
    @media (min-width: 1024px)
      .header-content-nav__subchoose,
      .sub-menu
        background: linear-gradient(270deg, #0083CC 68.62%, #019A9A 100%)

  .header-dark-blue
    background: linear-gradient(270deg, #0567aa 68.62%, #131c4c 100%)
    background: radial-gradient(20% 170.23% at max(50% - 630px, 7vw) 0%, #0567aa 10%, #131c4c 100%)
    background: radial-gradient(200px 100px at max(50% - 630px, 7vw) 0%, #0567aa 10%, #131c4c 100%)
    @media (min-width: 1024px)
      .header-content-nav__subchoose,
      .sub-menu
        background: linear-gradient(270deg, #131c4c 68.62%, #0567aa 100%)

  .bg-blue,
  .bg-green,
  .bg-dark-blue,
  .bg-dark-blue2,
  .bg-dark-blue3,
  .bg-b-green,
  .header-green,
  .header-blue,
  .header-dark-blue
    *
      color: #fff

    &.wrapper-form
      .form__head *,
      .form__main--check,
      .main__accept,
      .main__accept *
        color: #fff !important
      .form__main 
        .main__accept
          .checkmark
            border: 1px solid #fff 
          input.error ~ span.checkmark
            border-color: red

  .main-page
    // max-width: 1600px
    max-width: 1920px
    margin: auto
    overflow-x: hidden

  .wrapper-main
    @include centerFlex(1300px)
    flex-direction: column
    align-items: center
    margin: 0 auto
    padding: 40px 0
    position: relative
    &-ksiegowosc-online
      padding-bottom: 0
    &.z-index-on-top
      z-index: 2
    // box-shadow: 0 0 200px 0 #0000001A
    &.przedsiebiorca
      // z-index: 3
      padding-bottom: 0
    &.main-ksiegowy
      // z-index: 3
      padding-bottom: 0
    *
      box-sizing: border-box
    section
      padding-left: $gutter
      padding-right: $gutter
      // padding: 0 20px

  .main-fluid
    @include centerFlex(100%)
    box-shadow: none !important
    padding: 0

  .main-about
    padding: 0

  .main-map
    padding: 0
    z-index: 1
    .main-map__wrapper
      padding-bottom: 30%
      position: relative
      @media (max-width: 768px)
        padding-bottom: 100%

      iframe
        position: basolute
        top: 0
        bottom: 0
        left: 0
        right: 0
    @media (min-width: 768px)
      margin: -90px auto 0

  .page-btn,
  .button
    user-select: none
    box-sizing: border-box
    border-radius: 70px
    font-family: Nunito, sans-serif
    font-size: 18px
    font-style: normal
    font-weight: 700
    line-height: 1
    letter-spacing: 0em
    text-align: center
    height: 55px
    // width: 100%
    margin: 20px 10px 20px 0
    cursor: pointer
    text-decoration: none
    display: inline-flex
    align-items: center
    align-content: center
    justify-content: center
    padding: 0 40px

  .btn-sm
    box-sizing: border-box
    border-radius: 70px
    font-family: Nunito, sans-serif
    font-size: 14px
    font-style: normal
    font-weight: 700
    line-height: 1
    letter-spacing: 0em
    text-align: center
    height: 37px
    // width: 100%
    margin: 20px 10px 20px 0
    cursor: pointer
    text-decoration: none
    display: inline-flex
    align-items: center
    align-content: center
    justify-content: center
    padding: 0 35px

  .btn-blue
    background: radial-gradient(122.72% 100% at 2.53% 0%, #019A9A 0%, #0B70A9 100%)
    border: 1px solid #FFFFFF
    color: #FFFFFF
    transition: 333ms
    filter: brightness(1)
    &:hover
      filter: brightness(1.16)

  .btn-dark-blue
    background: radial-gradient(60.28% 173.9% at 0% -19.55%, #0085CF 0%, #0E366C 54.09%, #131C4C 100%)
    border: 1px solid #FFFFFF
    color: #FFFFFF
    transition: 333ms
    filter: brightness(1)
    &:hover
      filter: brightness(1.16)

  .btn-dark-blue2
    background: radial-gradient(152.77% 124.49% at 2.53% 0%, #0083CC 0%, #141D4D 100%)
    border: 1px solid #FFFFFF
    color: #FFFFFF
    transition: 333ms
    filter: brightness(1)
    &:hover
      filter: brightness(1.16)
  .btn-white-blue
    background: #fff
    border: 1px solid #0083CC
    color: #0083CC
    transition: 333ms
    &:hover
      background: rgba(#0083CC, 13%)

  .btn-white-dark-blue
    background: #fff
    border: 1px solid #0E2F6C
    color: #0E2F6C
    transition: 333ms
    &:hover
      background: rgba(#0083CC, 13%)

  .btn-white
    background: #fff
    border: 1px solid #01bf9c
    color: #0B8265
    transition: 333ms
    &:hover
      background: rgba(#0B8265, 10%)


  .btn-black
    background: #fff
    border: 1px solid #fff
    color: #000
    &:hover
      background: #eee

  .btn-green,
  .button--primary.button--cta.button--outline
    background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%)
    color: #FFFFFF
    border: 0px solid transparent
    transition: 333ms
    filter: brightness(1)
    &:hover
      filter: brightness(1.1)

  .section-bg-img
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    width: 100%
    height: 100%
    mix-blend-mode: multiply
    z-index: 0
    background-repeat: no-repeat
    background-size: cover
    background-position: center

  .wdth100
    width: 100%
    max-width: 100%

  .wdth35
    @media (min-width: 768px)
      max-width: 35%

  .wdth40-med
    @media (min-width: 768px) and (max-width: 1600px)
      max-width: 40%

  .wdth40
    @media (min-width: 1600px)
      width: 40%

  .wdth500
    max-width: 500px

  .mobile-hidden
    @media (max-width: 767.98px)
      display: none !important

  .text-center
    text-align: center !important

  .more-padding
    padding: 30px 0

  .no-shadow
    box-shadow: none !important


  .margin-side
    margin: 0 auto

  .flex-items-start
    align-items: flex-start

  .padding-top
    padding: 40px 0 0 !important

  .no-margin
    @media (max-width: 767.98px)
      margin: 0 !important

  .minus-margin
    @media (min-width: 768px)
      margin: -90px 0 0 !important

  .mt-50
    margin-top: 50px !important

  .mh-500
    max-height: 500px

  .maxw-0
    max-width: 0

  .no-margin
    margin: 0

  .d-flex
    display: flex !important

  .border-none
    border: none

  .margin-left-left
    @media (min-width: 768px)
      margin: auto auto auto -80px
      img:last-of-type
        transform: scale(0.75)
    @media (min-width: 768px) and (max-width: 1279.98px)
      img:first-child
        transform: scale(1.25)

  .font-light
    font-weight: 300

  .font-bold
    font-weight: 600

  .font-black
    font-weight: 900

  .flex-wrap
    flex-wrap: wrap

  .mh540
    max-height: 540px
// +++++++++++++++++++++++++++++++++++++++++++++++++
// +++++++++++++++++++++++++++++++++++++++++++++++++


.anim-home
  position: relative
  max-width: 100%
  @include for-phone-only
    max-width: none
    width: 160%
    transform: translateX(-10%)
  svg,
  img
    max-width: 100%
  svg
    position: absolute
    top: 0

.wrapper-bar-image
  margin: 0
  padding: 0
  width: 100%
  background: linear-gradient(90.48deg, #0083CC 50%, #141D4D 50%)
  img
    width: 100%
    display: block
    margin-left: auto
    margin-right: auto