
.wrapper-header-stuck-push
	transition: all .25s, box-shadow 2s
	z-index: 0
	min-height: 52px

	@media (min-width: 1024px)
		min-height: 93px
	

	
.wrapper-header
	@include centerFlex(100%)
	font-family: Montserrat, sans-serif
	margin: 0
	padding: 0
	justify-content: center
	align-items: center
	align-content: center
	position: fixed
	top: 0
	z-index: 99
	transition: all .25s, box-shadow 2s

	&.stuck
		box-shadow: #000 0 0 25px -10px
		@media (min-width: 1024px)
			min-height: 73px

	&.stuck .header-content-nav .sub-menu,
	&.stuck .header-content-nav__subchoose
		margin-top: 13px
	
	.header-content
		display: flex
	&.header-green .header-content .header-content-nav__subchoose
		position: absolute
		left: 0
		z-index: 3
		background: #009A9A
	&.header-blue .header-content .header-content-nav__subchoose
		position: absolute
		left: 0
		z-index: 3
		background: #0083CC
	&.header-dark-blue .header-content .header-content-nav__subchoose
		position: absolute
		left: 0
		z-index: 3
		background: radial-gradient(18.53% 116.05% at 15.75% 100%, #141D4D 0%, #131C4C 100%)

	@media (min-width: 1024px)
		min-height: 93px

	@media (max-width: 1023.98px)
		.header-content
			display: flex
			flex-direction: row
			position: relative
			margin: 0 auto
			padding: 0
			min-height: 66px
			transition: all .25s
			justify-content: space-between
			align-items: center
			width: 100%


			&__brand
				margin: 0 auto 0 0
				padding: 20px 30px
				display: inline-block
				transition: all .25s
				&--logo
					max-width: 95px

			&-nav
				transition: all .25s
				margin: 0
				position: absolute
				top: 66px
				top: 0
				right: 0
				left: auto
				height: 0
				flex-direction: column
				padding: 20px 30px
				transition: all .25s
				// width: 100%
				width: 320px
				align-items: flex-start
				display: flex
				visibility: hidden
				overflow: auto

					
				&.header-green
					background: radial-gradient(100% 100% at 0% 0%, #00CF9E 0%, #009A9A 100%)
				&.header-blue
					background: radial-gradient(100% 100% at 0% 0%, #019A9A 10%, #0083CC 100%)

				&.header-dark-blue
				background: radial-gradient(100% 100% at 0% 0%, #0567aa 10%, #131c4c 100%)

		
				&.toggled
					// height: calc(100vh - 90px)
					height: 100vh
					visibility: visible
					&:before
						content: ''
						background: #000000a6
						position: fixed
						inset: 0
						z-index: -1

					.header-content-nav__btns--btn:first-of-type
						position: static
						border-radius: 4px
						line-height: 15px
						text-align: center
						margin: 15px 0 10px
						font-size: 16px
						padding: 13px 26px
						height: auto

				&__choose,
				&__menu,
				&__subchoose,
				.sub-menu
					cursor: pointer
					list-style: none
					display: flex
					flex-direction: column
				&__subchoose,
				.sub-menu
					min-width: 100%

				&__choose,
				&__menu
					margin: 0
					padding: 0
					position: relative
					display: flex
					font-size: 12px
					@include for-tablet-up
						font-size: 14px
					&--item
						margin: 0
						padding: 0.5em 0

				&__choose
					border-bottom: 1px solid #F2F2F230
					margin: 0

				&__menu
					&--item
						font-style: normal
						font-weight: 300
						line-height: 20px
						letter-spacing: 0em
						font-size: 18px
						a
							text-decoration: none


				&__subchoose,
				.sub-menu
					display: none
					// position: absolute
					top: 100%
					margin: 0
					padding: 10px 0
					&--item,
					.header-content-nav__menu--item
						margin: 0
						padding: 0
						// line-height: 26px
						font-size: inherit
						cursor: pointer
						font-style: normal
						font-weight: 300
						letter-spacing: 0em
						a
							transition: all .25s
							display: block
							padding: 9px 18px
							text-decoration: none
							&:hover
								background: #F2F2F230

				&__btns
					margin: 0
					padding: 0
					display: flex
					flex-direction: column

					&--btn
						text-decoration: none
						font-family: Montserrat, sans-serif
						font-style: normal
						font-weight: 400
						letter-spacing: 0em
						border-radius: 4px
						line-height: 15px
						text-align: center
						margin: 0
						font-size: 16px
						padding: 13px 26px
						&:nth-of-type(odd)
							background-color: #F2F2F230
						&:nth-of-type(even)
							border: 1px solid #F2F2F230
						&:last-of-type
							margin: 20px 0
						&:first-of-type
							visibility: visible
							position: absolute
							top: -44px
							border-radius: 0
							right: 45px
							height: 22px
							font-size: 12px
							line-height: 22px
							margin: 0
							padding: 0 8px
							z-index: 11
						&.inverse
							background: #fff
							color: #009A9A 

			.menu-colapse
				// position: absolute
				display: flex
				flex-direction: column
				align-items: center
				align-content: center
				justify-content: center
				width: 24px
				height: 22px
				background-color: #F2F2F230
				right: 10px
				top: 22px
				cursor: pointer
				text-align: center
				margin-left: 28px
				margin-right: 10px
				span
					width: 16px
					display: block
					height: 2px
					background-color: #fff
					transition: all 0.25s
					margin: 0
					padding: 0
					&:nth-of-type(2)
						margin: 3px 0
				&.toggled
					span
						&:nth-of-type(1)
							transform: rotate(45deg) translate(3px, 3px)
						&:nth-of-type(2)
							opacity: 0
						&:nth-of-type(3)
							transform: rotate(-45deg) translate(4px, -4px)


	@media (min-width: 1024px)
		.header-content
			@include centerFlex(1366px)
			margin: 0 auto
			padding: 0
			justify-content: space-between
			align-items: center

			&__brand
				margin: 0
				padding: 10px
				@media (min-width: 1300px)
					padding: 20px

				&--logo
					max-width: 150px

			&-nav
				@include centerFlex(100%)
				margin: 0
				padding: 10px
				@media (min-width: 1300px)
					padding: 10px 20px

				&.header-green,
				&.header-blue,
				&.header-dark-blue
					background: transparent

				&__choose
					background: #F2F2F230
					border-radius: 4px
					padding: 0
					margin: 0
					&--item
						display: flex
						
						i
							margin-left: 5px
					@media (min-width: 1300px)
						padding: 0
						margin: 0


				&__choose,
				&__menu,
				&__subchoose,
				.sub-menu
					list-style: none
					&--item
						cursor: pointer
						font-style: normal
						font-weight: 300
						line-height: 17px
						letter-spacing: 0em
						font-size: 12px
						@media (min-width: 1300px)
							font-size: 14px
						a
							text-decoration: none

				&__menu,
				&__subchoose,
				.sub-menu
					margin: 0
					padding: 0


				&__choose,
				&__menu
					position: relative
					display: flex
					white-space: nowrap
					&--item
						margin: 0
						padding: 1em 15px
						@media (min-width: 1300px)
							padding: 1em 14px

				&__subchoose
					left: -22px
					margin: 25px 0 0
					min-width: 100%
					@media (min-width: 1300px)
						left: 0
						margin: 24px 0 0

				.sub-menu
					margin: 25px 0 0 -22px
					@media (min-width: 1300px)
						margin: 24px 0 0 -22px

				&__subchoose,
				.sub-menu
					display: none
					position: absolute
					top: 100%
					padding: 10px 0
					
					&:before
						top: 0
						left: 0
						right: 0
						height: 1px
						content: ""
						max-width: 100%
						padding: 0
						position: absolute
						background-color: #fff
						margin: 0 22px

					&--item,
					.header-content-nav__menu--item
						margin: 0
						padding: 0
						a
							transition: all .25s
							display: block
							padding: 9px 18px
							@media (min-width: 1300px)
								padding: 13px 22px
							&:hover
								background: #F2F2F230


				&__btns
					margin: 0 0 0 auto
					padding: 0
					display: flex

					&--btn
						display: inline-flex
						align-items: center
						border-radius: 4px
						text-decoration: none
						font-family: Montserrat, sans-serif
						font-style: normal
						font-weight: 400
						line-height: 15px
						letter-spacing: 0em
						text-align: center
						margin: 0 5px
						font-size: 10px
						padding: 9px 18px
						transition: 333ms
						
						&:hover
							background: #ffffff48
						&.inverse
							background: #fff
							color: #009A9A 
							&:hover
								background: #ffffffe3

						@media (min-width: 1300px)
							margin: 0 5px
							font-size: 12px
							padding: 13px 40px
						&:nth-of-type(odd)
							background-color: #F2F2F230
							&:hover
								background-color: #ffffff48
						
						&:nth-of-type(even)
							border: 1px solid #F2F2F230
						// &:last-of-type
						// 	display: none

		.menu-colapse
			display: none
