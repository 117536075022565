
@keyframes bounce-7
	0%   
		transform: scale(1,1) translateY(0)
	10%  
		transform: scale(1.1,.9) translateY(0)
	30%  
		transform: scale(.9,1.1) translateY(-20px)
	50%  
		transform: scale(1.05,.95) translateY(0)
	57%  
		transform: scale(1,1) translateY(-7px)
	64%  
		transform: scale(1,1) translateY(0)
	100% 
		transform: scale(1,1) translateY(0)


/* Generated with Bounce.js. Edit at http://bouncejs.com#%7Bs%3A%5B%7BT%3A%22c%22%2Ce%3A%22b%22%2Cd%3A1000%2CD%3A0%2Cf%3A%7Bx%3A0.9%2Cy%3A0.9%7D%2Ct%3A%7Bx%3A1%2Cy%3A0.9%7D%2Cs%3A1%2Cb%3A4%7D%2C%7BT%3A%22c%22%2Ce%3A%22b%22%2Cd%3A1000%2CD%3A0%2Cf%3A%7Bx%3A0.9%2Cy%3A0.9%7D%2Ct%3A%7Bx%3A0.9%2Cy%3A1%7D%2Cs%3A1%2Cb%3A6%7D%5D%7D */

@keyframes jelly 
	0% 
		transform: matrix3d(0.81, 0, 0, 0, 0, 0.81, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	3.4% 
		transform: matrix3d(0.838, 0, 0, 0, 0, 0.847, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	4.7% 
		transform: matrix3d(0.85, 0, 0, 0, 0, 0.864, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	6.81% 
		transform: matrix3d(0.869, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	9.41% 
		transform: matrix3d(0.89, 0, 0, 0, 0, 0.915, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	10.21% 
		transform: matrix3d(0.895, 0, 0, 0, 0, 0.92, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	13.61% 
		transform: matrix3d(0.911, 0, 0, 0, 0, 0.93, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	14.11% 
		transform: matrix3d(0.913, 0, 0, 0, 0, 0.93, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	17.52% 
		transform: matrix3d(0.919, 0, 0, 0, 0, 0.922, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	18.72% 
		transform: matrix3d(0.919, 0, 0, 0, 0, 0.917, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	21.32% 
		transform: matrix3d(0.918, 0, 0, 0, 0, 0.906, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	24.32% 
		transform: matrix3d(0.914, 0, 0, 0, 0, 0.896, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	25.23% 
		transform: matrix3d(0.912, 0, 0, 0, 0, 0.894, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	29.03% 
		transform: matrix3d(0.906, 0, 0, 0, 0, 0.891, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	29.93% 
		transform: matrix3d(0.904, 0, 0, 0, 0, 0.891, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	35.54% 
		transform: matrix3d(0.898, 0, 0, 0, 0, 0.897, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	36.74% 
		transform: matrix3d(0.897, 0, 0, 0, 0, 0.898, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	41.04% 
		transform: matrix3d(0.896, 0, 0, 0, 0, 0.902, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	44.44% 
		transform: matrix3d(0.897, 0, 0, 0, 0, 0.903, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	52.15% 
		transform: matrix3d(0.899, 0, 0, 0, 0, 0.901, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	59.86% 
		transform: matrix3d(0.901, 0, 0, 0, 0, 0.899, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	63.26% 
		transform: matrix3d(0.901, 0, 0, 0, 0, 0.899, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	75.28% 
		transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	85.49% 
		transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	90.69% 
		transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
	100% 
			transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1) 
	



.wrapper-tooltips
	@include centerFlex(100%)
	padding: 0
	margin: 50px 0 0
	// background: url(../img/leaf.jpg) no-repeat right bottom / auto
	position: relative
	justify-content: center
	align-items: center
	align-content: center
	@media (max-width: 767.98px)
		background: none
		margin: 0
		padding: 0 0 40px

	&--img
		display: block
		margin: auto
		max-width: 100%

	&__gfx
		max-width: 1300px
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
		margin: auto
		height: 100%
		width: 100%
		@media (max-width: 767.98px)
			display: none

		img
			position: absolute
			&:nth-of-type(1)
				top: 10%
				left: 5%
			&:nth-of-type(2)
				top: -10%
				right: 35%
			&:nth-of-type(3)
				top: 20%
				right: 10%
			&:nth-of-type(4)
				left: 55%
				bottom: 5%
			&:nth-of-type(5)
				bottom: 0%
				left: 5%

.wrapper-numbers
	@include centerFlex(900px)
	margin: 0 auto 80px !important
	flex-direction: column
	align-items: center
	justify-content: center
	padding: 0 !important

	@media (max-width: 767.98px)
	margin: 0 auto 20px !important


	&-boxes
		@include centerFlex(100%)
		margin: 0 auto !important
		justify-content: space-between
		align-items: flex-start
		padding: 0

		&-num
			@include centerFlex(33%)
			margin: 0 auto !important
			flex-direction: column
			align-items: center
			justify-content: center
			padding: 0

			&__ico
				display: flex
				justify-content: center
				align-items: center
				align-content: center
				height: 100px
				width: 100px
				border-radius: 120px
				@media (max-width:  767.98px)
					height: 65px
					width: 65px

				&--svg
					max-width: 70%

			&--title
				font-family: PT Sans, sans-serif
				font-size: 48px
				font-style: normal
				font-weight: 400
				line-height: 62px
				letter-spacing: 0em
				text-align: center
				margin: 0
				@media (max-width:  767.98px)
					font-size: 18px
					line-height: 23px

			&--desc
				font-family: Nunito, sans-serif
				font-size: 18px
				font-style: normal
				font-weight: 300
				line-height: 25px
				letter-spacing: 0em
				text-align: center
				@media (max-width:  767.98px)
					font-size: 12px
					line-height: 16px

	&--img
		max-width: 70%

	&--box
		font-family: Nunito, sans-serif
		font-size: 18px
		font-style: normal
		font-weight: 900
		line-height: 23px
		letter-spacing: 0em
		text-align: center
		background: #FFFFFF
		box-sizing: border-box
		border-radius: 50px
		letter-spacing: 0em
		text-align: center
		padding: 30px 50px
		box-shadow: 4px 4px 60px 0px #00000026
		position: relative
		@media (max-width:  767.98px)
			font-size: 12px
			line-height: 18px
			padding: 12px 50px
		@media (min-width: 768px)
			&:after
				content: ""
				width: 1600px
				height: 1px
				position: absolute
				background-color: #BDBDBD
				top: 50px
				right: -160%
				margin: auto
				z-index: -1

.wrapper-boxes-slider
	transition: all 0.25s
	margin: 0 auto
	padding: 80px !important
	@include centerFlex(1300px)
	flex-direction: column
	@media (max-width: 1279.98px)
		padding: 0 !important
	&:after
		position: absolute
		bottom: 0
		left: -150px
		right: 0
		margin: auto
		width: 1600px
		height: 1px
		background: #BDBDBD
		content: ""
	.slick-track
		display: flex !important
	.slick-list
		overflow: visible
		@media (max-width: 1279.98px)
			max-width: 330px
	.slick-dots
		list-style: none
		display: flex
		margin: 20px auto
		padding: 0

		li.slick-active
			background: #01AE9C
			height: 16px
			width: 27px
			.main-ksiegowosc-online &
				background: #141D4D



		li
			border: 1px solid #01AE9C
			.main-ksiegowosc-online &
				border: 1px solid #141D4D
			height: 16px
			width: 16px
			border-radius: 40px
			margin: 2px
			button
				visibility: hidden

	@media (min-width: 1280px)
		.slick-dots
			display: none

	&__box
		@include centerFlex(315px)
		user-select: text
		padding: 30px
		margin: 0 3px
		height: auto !important
		border-radius: 10px
		flex-direction: column
		@media (max-width: 1279.98px)
			margin: 0 auto
		&:nth-of-type(1)
			background: radial-gradient(164.28% 117.44% at 11.35% 6.15%, #00CF9E 10.42%, #009A9A 100%)
			.main-ksiegowosc-online &
				background: radial-gradient(139.37% 100% at 0% 0%, #00B7CF 0%, #141D4D 100%)
		&:nth-of-type(2)
			background: radial-gradient(174.74% 125.51% at 11.35% 6.15%, #10D59A 10.42%, #045940 100%)
			.main-ksiegowosc-online &
				background: radial-gradient(152.77% 124.49% at 2.53% 0%, #0083CC 0%, #141D4D 100%)
		&:nth-of-type(3)
			background: radial-gradient(152.77% 124.49% at 2.53% 0%, #52D2B4 0%, #11425E 100%)
			.main-ksiegowosc-online &
				background: radial-gradient(174.74% 125.51% at 11.35% 6.15%, #10487B 0%, #141D4D 100%)
		&:nth-of-type(4)
			background: radial-gradient(149.39% 107.44% at 11.35% 6.15%, #0D8B8B 0%, #0B407E 100%)
			.main-ksiegowosc-online &
				background: radial-gradient(138.19% 99.49% at 95.25% 97.18%, #212122 0%, #0D4079 94.23%)

		&--ico
			margin: 0
			padding: 0
			height: 100%
			max-height: 76px
			position: relative
			display: flex
			justify-content: flex-end
			align-items: center
			&::before
				left: 0
				position: absolute
				content: ""
				width: 150px
				height: 3px
				background: #fff
				@media (max-width:  767.98px)
					width: 115px

			img
				max-width: 100%
				display: block

		&--title
			font-family: Nunito, sans-serif
			font-size: 24px
			font-style: normal
			font-weight: 700
			line-height: 28px
			letter-spacing: 0em
			text-align: left
			max-width: 214px
			color: #fff
			@media (max-width:  767.98px)
				max-width: 150px
				margin-top: -20px
				font-size: 18px
				line-height: 24px

		&--desc
			font-family: Montserrat, sans-serif
			font-size: 14px
			font-style: normal
			font-weight: 500
			line-height: 24px
			letter-spacing: 0em
			text-align: left
			color: #fff
			@media (max-width:  767.98px)
				font-size: 12px
				font-weight: 18px

.wrapper-help
	@include centerFlex(1100px)
	margin: 0
	flex-wrap: wrap
	@media (max-width: 767.98px)
		justify-content: center
		padding: 0 !important
		align-items: center
	@media (min-width: 768px)
		padding: 0 20px 40px !important

	&--title
		width: 100%
		font-family: Nunito, sans-serif
		font-size: 40px
		font-style: normal
		font-weight: 300
		line-height: 55px
		letter-spacing: 0em
		text-align: center
		margin: 20px 0 -10px
		@media (max-width: 767.98px)
			text-align: left
			width: 74%
			font-size: 24px
			line-height: 33px
			margin: 0 0 0px
			padding: 0 0 0 30px

	&__ico
		width: 100%
		margin: 0
		padding: 0
		height: 100%
		max-height: 76px
		position: relative
		display: flex
		justify-content: flex-end
		align-items: center
		@media (max-width:  767.98px)
			max-width: 50px
			margin: 0 40px 30px 0




		&--svg
			max-width: 100%
			display: none
			&.item-active
				display: inline
				animation: bounce-7 1s both
			@media (min-width: 768px) and (max-width: 1079.98px)
				max-width: 10%
		&::before
			left: 0
			position: absolute
			content: ""
			width: 85%
			height: 2px
			background: #fff
			@media (max-width:  767.98px)
				display: none

	&-main
		@include centerFlex(100%)
		@media (max-width:  767.98px)
			border-top: 0px solid #fff
			flex-direction: column

		&__menu
			@include centerBlock(360px)
			margin: 0
			padding: 0 60px 0 0
			@media (min-width:  768px)
				border-right: 1px solid #fff
			@media (max-width:  767.98px)
				width: 100%
				max-width: auto
				padding: 0 0 40px
				margin: 20px 40px 0
				border-bottom: 1px solid #fff

			&--item
				font-family: Nunito, sans-serif
				font-size: 16px
				font-style: normal
				font-weight: 400
				line-height: 20px
				letter-spacing: 0em
				text-align: left
				cursor: pointer
				margin: 0 0 20px
				&.item-active
					text-decoration: underline
					font-weight: 700
				@media (max-width:  767.98px)
					margin: 8px 0 0
					font-size: 14px
					line-height: 15px

		&-content
			@include centerBlock(640px)
			padding: 0 0 0 40px

			@media (max-width:  767.98px)
				padding: 0 40px 0
				margin: 20px 0 0 0

			&__info
				display: none
				margin: 0
				padding: 0
				flex-direction: column
				height: 100%
					
				@include for-tablet-up
					min-height: 360px 
					
				&.info-visible
					@include centerFlex(100%)

				&--title
					// font-family: Montserrat, sans-serif
					font-family: Nunito, sans-serif
					font-size: 24px
					font-style: normal
					font-weight: 300
					line-height: 29px
					letter-spacing: 0em
					text-align: left
					margin: 0 0 25px
					@media (max-width:  767.98px)
						display: none

				&--desc
					margin: 0 0 20px
					// font-family: Montserrat, sans-serif
					font-family: Nunito, sans-serif
					font-size: 16px
					font-style: normal
					font-weight: 300
					line-height: 1.5
					letter-spacing: 0em
					text-align: left
					// min-height: 16.3em
					// height: 16.3em
					&.more	
						height: auto
						-webkit-line-clamp: none
					a
						font-weight: bold
					@media (max-width:  767.98px)
						font-size: 14px
						line-height: 1.5
				.more
					display: inline-block
					margin: -1em 0 1em
					i
						font-size: 0.8em

				.btn-black
					font-size: 14px
					line-height: 19px
					margin: 5px auto 0 0

.wrapper-priceboxes
	@include centerFlex(1040px)
	justify-content: space-around
	z-index: 1
	@media (max-width: 767.98px)
		margin: 0 0 40px
	@media (min-width: 768px)
		margin: 20px 0 -20px

	.slick-track
		display: flex !important
		justify-content: space-around
		@media (min-width: 1280px)
			width: 100% !important
	.slick-list
		overflow: visible
		width: 100%
		@media (max-width: 1279.98px)
			max-width: 330px
	.slick-dots
		list-style: none
		display: flex
		position: absolute
		bottom: -70px
		margin: 20px auto
		padding: 0

		li.slick-active
			background: #01AE9C
			height: 16px
			width: 27px
			@media (min-width: 768px)
				background: #fff

		li
			border: 1px solid #01AE9C
			height: 16px
			width: 16px
			border-radius: 40px
			margin: 2px
			@media (min-width: 768px)
				border: 1px solid #fff
			button
				visibility: hidden

	@media (min-width: 1280px)
		.slick-dots
			display: none !important

	&-box
		@include centerFlex(305px)
		display: flex !important
		height: auto !important
		flex-direction: column
		margin: 0 auto
		background-color: #fff
		box-shadow: 0px 0px 15px 0px #00000026
		user-select: text
		&:nth-of-type(1),
		&:nth-of-type(3)
			.wrapper-priceboxes-box__head--title
				background: radial-gradient(100% 381.59% at 0% 6.83%, #00CF9E 0%, #009A9A 100%)
				padding: 15px 35px
				margin: 0
				.main-ksiegowosc-online &
					background: radial-gradient(152.77% 124.49% at 2.53% 0%, #0083CC 0%, #141D4D 100%)

			.wrapper-priceboxes-box__head--subtitle
				color: #019C9A
				.main-ksiegowosc-online &
					color: #131C4C


		&:nth-of-type(2)
			.wrapper-priceboxes-box__head
				padding: 0 35px 5px
				background: radial-gradient(100% 381.59% at 0% 6.83%, #00CF9E 0%, #009A9A 100%)
				margin: -15px -10px -10px
				.main-ksiegowosc-online &
					background:  radial-gradient(152.77% 124.49% at 2.53% 0%, #0083CC 0%, #141D4D 100%)

				&--title
					border-bottom: 1px solid #fff
					padding: 16px 20px

				&--subtitle
					margin: 0 0 20px

				*
					color: #fff



		&__head

			*
				text-align: center

			&--title
				text-align: center
				margin: 0
				font-family: Montserrat, sans-serif
				font-size: 20px
				font-style: normal
				font-weight: 600
				line-height: 18px
				letter-spacing: 0em
				text-align: center
				color: #fff


			&--price
				font-family: Montserrat, sans-serif
				font-size: 14px
				font-style: normal
				font-weight: 600
				line-height: 18px
				letter-spacing: 0em
				text-align: center
				padding: 25px 10px 35px
				margin: 10px 0 0

				span
					font-family: Montserrat, sans-serif
					font-size: 43px
					font-style: normal
					font-weight: 700
					line-height: 18px
					letter-spacing: 0em
					text-align: center

			&--subtitle
				font-family: Montserrat, sans-serif
				font-size: 14px
				font-style: normal
				font-weight: 700
				line-height: 18px
				letter-spacing: 0em
				text-transform: uppercase
				margin: 0



		&--btns
			@include centerFlex(100%)
			flex-direction: column
			padding: 35px 35px 25px

			a
				width: 100%
				margin: 0 0 10px
				font-size: 14px
				line-height: 19px
				font-weight: 400
				height: 39px

		&__options
			@include centerFlex(235px)
			flex-direction: column
			padding: 20px 0
			margin: 0 auto
			border-top: 1px solid #E0E0E0
			flex-grow: 1
			height: auto

			&--general
				border-bottom: 1px solid #E0E0E0
				padding: 0 0 20px
				height: auto
				flex-grow: 1

			&--show
				margin: 10px 0
				text-align: center
				font-family: Montserrat, sans-serif
				font-size: 12px
				font-style: normal
				font-weight: 300
				line-height: 25px
				letter-spacing: 0em
				text-align: center
				text-decoration: underline
				cursor: pointer

			&--hidden
				display: none
				padding: 0 0 80px

			p
				padding: 0
				margin: 0

			ul
				margin: 0
				padding: 0 0 0 20px

			li
				list-style-image: url(../img/check.png)
				font-family: Montserrat, sans-serif
				font-size: 12px
				font-style: normal
				line-height: 25px
				letter-spacing: 0em
				text-align: left
				.main-ksiegowosc-online &
					list-style-image: url(../ksiegowosc-online/img/check.png)


.wrapper-cal
	@include centerFlex(920px)
	justify-content: space-around
	align-items: center
	align-content: center
	@media (max-width: 767.98px)
		flex-direction: column
		margin: 30px 0 0
	@media (min-width: 768px)
		padding: 60px 0 0 !important

	&--img
		max-width: 40%

	&__head
		padding: 40px
		margin: 0
		@media (max-width: 767.98px)
			padding: 0 30px

		&--pretitle
			font-family: Nunito, sans-serif
			font-size: 28px
			font-style: normal
			font-weight: 300
			line-height: 28px
			letter-spacing: 0em
			text-align: left
			margin: 0
			@media (max-width: 767.98px)
				display: none

		&--title
			font-family: Nunito, sans-serif
			font-size: 41px
			font-style: normal
			font-weight: 700
			line-height: 49px
			letter-spacing: 0em
			text-align: left
			margin: 20px 0
			max-width: 340px
			@media (max-width: 767.98px)
				text-align: center
				font-size: 24px
				line-height: 28px
				max-width: 200px
				margin: 10px auto

		&--subtitle
			margin: 0 0 35px
			font-family: Nunito, sans-serif
			font-size: 19px
			font-style: normal
			font-weight: 300
			line-height: 23px
			letter-spacing: 0em
			text-align: left
			@media (max-width: 767.98px)
				display: none

.wrapper-used-function
	@include centerBlock(1080px)
	margin: 0
	padding: 0
	position: relative

	&--title
		font-family: Nunito, sans-serif
		font-size: 40px
		font-style: normal
		font-weight: 300
		line-height: 55px
		letter-spacing: 0em
		text-align: center
		margin: 30px 0 50px
		@media (max-width: 767.98px)
			font-size: 24px
			line-height: 32px


	&-main
		@include centerFlex(100%)
		flex-wrap: wrap
		margin-bottom: 40px
		z-index: 3
		@media (min-width:1080px)
			justify-content: flex-start
			padding-left: 21px
			margin-bottom: 70px
		&.slick-initialized .slick-slide
			display: flex !important
		.slick-track
			display: flex !important
			justify-content: space-around
			@media (min-width: 1080px)
				width: 100% !important
		.slick-list
			overflow: visible
			width: 100%
			@media (max-width: 1079.98px)
				max-width: 330px
		.slick-dots
			list-style: none
			display: flex
			position: absolute
			margin: 20px auto
			padding: 0
			right: 0
			left: 0
			justify-content: center
			bottom: -30px
			@media (min-width: 1080px)
				bottom: -70px

			li.slick-active
				background: #01AE9C
				height: 16px
				width: 27px

			li
				border: 1px solid #01AE9C
				height: 16px
				width: 16px
				border-radius: 40px
				margin: 2px
				button
					visibility: hidden

		&-box
			@include centerFlex(100%)
			flex-direction: column
			margin: 0 0 0px
			@media (max-width: 1079.98px)
				position: sticky
				top: 70px
			@media (min-width: 1080px)
				margin-bottom: 50px
				@include centerFlex(317px)
				&:nth-of-type(2),
				&:nth-of-type(5)
					margin: 0 18px 50px

			&.func-active
				
				.fx-image
					margin: auto
					img
						max-width: 100%
				.fx-anim
					padding-bottom: 0%
					// aspect-ratio: 1
				.fx-anim,
				.fx-image
					width: 70%
					
					display: flex
					algin-items: center
					justify-content: center
					// img
						// max-width: 70%
						
						
				@media (max-width: 1079.98px)
					position: static

				@media (max-width: 1079px)
					.wrapper-used-function-main-box__main--desc-small
						display: none
					.wrapper-used-function-main-box__main--teaser
						.wrapper-used-function-main-box__main--show
							display: none
					.wrapper-used-function-main-box__main
						display: flex
						
				@media (min-width: 1080px)
					// min-height: 520px

					.wrapper-used-function-main-box__head
						z-index: 2

						&:before
							box-shadow: 0 0 20px 10px #0000001A
							background-color: #fff
							z-index: -1
							*
								z-index: 2

					.wrapper-used-function-main-box__main
						display: flex
						position: absolute
						max-height: 430px
						max-height: none
						min-height: 400px
						height: auto
						padding: 55px 110px 35px
						left: 0
						margin-top: 120px
						z-index: 6
						&--desc
							z-index: 3
							max-width: 500px

						.fx-anim
							position: absolute
							top: 30px
							right: 0
							width: 35%
							animation: jelly 1s linear both
							padding-bottom: 0
							// aspect-ratio: 1
							

						.fx-image
							animation: jelly 1s linear both
							position: absolute
							top: 30px
							right: 0
							width: 35%
							display: flex
							algin-items: center
							justify-content: center
							img
								max-width: none





						&:before
							box-shadow: 0 20px 20px 10px #0000001A
							background-color: #fff


			&__head
				@include centerFlex(100%)
				align-items: center
				align-content: center
				flex-wrap: wrap
				margin: 25px 0
				cursor: pointer
				@media (min-width: 1080px)
					&:before
						transition: all 0.25s
						position: absolute
						height: 150px
						width: 400px
						margin: 0 0 0 -36px
						content: ""

				&--top
					width: 100%
					height: 100vh
					max-height: 13px
					display: block
					margin: 0 0 25px

				&--ico
					margin: 0 10px 0 0
					display: flex
					justify-content: center
					align-items: center
					align-content: center
					height: 55px
					width: 55px

					img
						max-width: 100%

				&--title
					font-family: Montserrat, sans-serif
					font-size: 16px
					font-style: normal
					font-weight: 700
					line-height: 24px
					letter-spacing: 0em
					text-align: left
					margin: 0
					max-width: 245px
					max-width: 205px

			&__main
				@include centerFlex(100%)
				display: none
				flex-direction: column
				height: 100%
				
				@media (min-width: 1080px)

					&:before
						// transition: all 0.25s
						position: absolute
						height: 105%
						width: calc(100% + 40px)
						left: -15px
						margin: auto
						top: 0
						content: ""
						z-index: -1

				&--teaser,
				&--desc
					// position: relative
					font-family: Montserrat, sans-serif
					font-size: 14px
					font-style: normal
					font-weight: 300
					line-height: 24px
					letter-spacing: 0em
					text-align: left
					margin: 0 0 15px
					// min-height: 145px
					@media (max-width: 767.98px)
						font-size: 12px
						line-height: 24px
						.fx-anim
							margin-top: 20px

				&--teaser
					display: flex
					flex-direction: column
					height: 100%
					margin-bottom: 0
				.wrapper-used-function-main-box__main 
					&--show
						margin-top: 1em

				&--show
					font-family: Montserrat, sans-serif
					font-size: 16px
					font-style: normal
					font-weight: 300
					line-height: 24px
					letter-spacing: 0em
					text-align: left
					text-decoration: underline
					cursor: pointer
					margin: auto auto 0 0
					position: relative
					z-index: 5

					@media (max-width: 767.98px)
						font-size: 12px
						line-height: 24px

.wrapper-acc-price
	margin: 0 auto
	padding: 55px 20px !important
	@include centerFlex(100%)
	flex-direction: column
	align-items: center
	align-content: center

	&--pretitle
		margin: 0
		padding: 0
		font-family: Nunito, sans-serif
		font-size: 14px
		font-style: normal
		font-weight: 300
		line-height: 1.5em
		letter-spacing: 0em
		text-align: center


	&--title
		margin: 0
		padding: 0
		font-family: Nunito, sans-serif
		font-size: 34px
		font-style: normal
		font-weight: 300
		line-height: 1.2em
		letter-spacing: 0em
		text-align: center


	&-box
		@include centerFlex(600px)
		flex-direction: column
		background: linear-gradient(261.41deg, #FFFFFF -6.58%, #D6D6D6 5.04%, #FFFFFF 23.61%)
		box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25)
		border-radius: 4px
		align-items: center
		padding: 25px

		&--head
			@include centerFlex(325px)
			flex-direction: column

			span
				display: block

				&:nth-of-type(1)
					background: radial-gradient(100% 381.59% at 0% 6.83%, #00CF9E 0%, #019A9A 0.01%, #0B70A9 100%)
					font-family: Montserrat, sans-serif
					font-size: 12px
					font-style: normal
					font-weight: 700
					line-height: 31px
					letter-spacing: 0em
					text-align: center
					height: 31px
					width: 133px
					border-radius: 12px

				&:nth-of-type(2)
					font-family: Montserrat, sans-serif
					font-size: 60px
					font-style: normal
					font-weight: 700
					line-height: 88px
					letter-spacing: 0em
					text-align: center
					color: #000
					@media (min-width:768px)
						font-size:80px

				&:nth-of-type(3)
					color: #000
					font-family: Montserrat, sans-serif
					font-size: 30px
					font-style: normal
					font-weight: 300
					line-height: 30px
					letter-spacing: 0em
					text-align: center

		&--desc
			margin: 5px 0 0
			font-family: Nunito, sans-serif
			font-size: 18px
			font-style: normal
			font-weight: 300
			line-height: 25px
			letter-spacing: 0em
			text-align: center
			color: #000 !important

		&--btns
			text-align: center
			width: 100%
			margin: 15px 0 0
			padding: 0

			.page-btn
				height: 39px !important
				max-width: 230px
				font-size: 14px !important
				margin: 5px
				font-weight: 400 !important

.wrapper-comparision
	@include centerBlock(1080px)
	margin: 0 auto
	padding: 0

	&-main
		@include centerFlex(100%)
		flex-direction: column
		margin: 0
		@media (min-width: 819.98px)
			margin: 135px 0


		&-headrow
			@include centerFlex(100%)
			margin: 0
			@media (max-width: 819.98px)
				flex-direction: column
				align-items: center

			&--title
				width: 100%
				font-family: Nunito, sans-serif
				font-size: 40px
				font-style: normal
				font-weight: 300
				line-height: 55px
				letter-spacing: 0em
				text-align: left
				@media (max-width: 819.98px)
					margin: 50px 0 20px
					text-align: center
					font-size: 18px
					line-height: 25px


			&-box
				flex-direction: column
				align-items: center
				@media (max-width: 819.98px)
					@include centerFlex(340px)
				@media (min-width: 820px)
					@include centerFlex(183px)
					&:nth-of-type(1)
						.wrapper-comparision-main-headrow-box__head
							border-right: 4px solid #fff
					&:nth-of-type(3)
						.wrapper-comparision-main-headrow-box__head
							border-left: 4px solid #fff
					&:nth-of-type(odd)
						border-left: 1px solid #ADDBD2
						border-right: 1px solid #ADDBD2
						.main-ksiegowosc-online &
							border-left: 1px solid #D7D7D7
							border-right: 1px solid #D7D7D7

				&:nth-of-type(2)
					.wrapper-comparision-main-headrow-box__main
						background: radial-gradient(100% 381.59% at 0% 6.83%, #00CF9E 0%, #009A9A 100%)
						.main-ksiegowosc-online &
							background: radial-gradient(152.77% 124.49% at 2.53% 0%, #0083CC 0%, #141D4D 100%)
						*
							color: #fff



				&__head
					@include centerFlex(100%)
					flex-direction: column
					justify-content: center
					align-items: center
					align-content: center
					background: radial-gradient(100% 381.59% at 0% 6.83%, #00CF9E 0%, #009A9A 100%)
					height: 90px
					border-bottom: 4px solid #fff
					.main-ksiegowosc-online &
						background: radial-gradient(152.77% 124.49% at 2.53% 0%, #0083CC 0%, #141D4D 100%)
					@media (max-width: 819.98px)
						height: 70px

					&--title
						font-family: Nunito, sans-serif
						font-size: 18px
						font-style: normal
						font-weight: 300
						line-height: 25px
						letter-spacing: 0em
						text-align: center
						color: #fff

				&__main
					@include centerFlex(100%)
					flex-direction: column
					justify-content: center
					align-items: center
					align-content: center
					height: 140px
					padding: 0 30px

					&--price
						font-family: Montserrat, sans-serif
						font-size: 14px
						font-style: normal
						font-weight: 400
						line-height: 18px
						letter-spacing: 0em
						text-align: center
						color: #000
						margin: 0

						span

							&:nth-of-type(1)
								color: #000
								font-family: Montserrat, sans-serif
								font-size: 22px
								font-style: normal
								font-weight: 700
								line-height: 18px
								letter-spacing: 0em
								text-align: center


					.page-btn
						height: 38px
						width: 130px
						border-radius: 70px
						font-size: 10px
						font-weight: 800
						line-height: 14px
						padding: 0
						margin: 15px 0 0 0
						&.btn-black
							color: #000

				&__foot
					@include centerFlex(100%)
					flex-direction: column
					align-items: center
					align-content: center
					border-top: 1px solid #ADDBD2
					padding: 5px 10px 0 30px
					max-height: 460px
					overflow: hidden
					@media (min-width: 820px)
						display: none

					&--item
						width: 100%
						font-family: Montserrat, sans-serif
						font-size: 12px
						font-style: normal
						font-weight: 300
						line-height: 25px
						letter-spacing: 0em
						text-align: left
						position: relative
						margin: 0
						&:before
							position: absolute
							left: -25px
							content: url(../../img/check2.png)

			.show--more
				@include centerFlex(100%)
				flex-direction: column
				justify-content: center
				align-items: center
				align-content: center
				height: 60px
				cursor: pointer
				@media (min-width: 820px)
					display: none

		&-bodyrow
			@include centerFlex(100%)
			margin: 0
			position: relative
			@media (max-width: 819.98px)
				display: none
			&:last-of-type
				.wrapper-comparision-main-bodyrow--title,
				.wrapper-comparision-main-bodyrow-box
					padding: 0 0 30px 0

			*
				margin: 0
				padding: 0 0 5px 0

			&--title
				width: 100%
				font-family: Montserrat, sans-serif
				font-size: 12px
				font-style: normal
				font-weight: 300
				line-height: 25px
				letter-spacing: 0em
				text-align: left

			&-box
				@include centerFlex(183px)
				flex-direction: column
				align-items: center
				justify-content: center
				align-content: center

				&:nth-of-type(odd)
					border-left: 1px solid #ADDBD2
					border-right: 1px solid #ADDBD2
					.main-ksiegowosc-online &
						border-left: 1px solid #D7D7D7
						border-right: 1px solid #D7D7D7
				&__main
					@include centerFlex(100%)
					flex-direction: column
					justify-content: center
					align-items: center
					align-content: center
					padding: 0 12px
					*
						max-width: 100%

.wrapper-price-list-tabs
	margin-top: 2rem
	border-radius: 70px
	max-width: 900px
	width: 91%
	flex-wrap: nowrap
	padding: 3px
	gap: 0
	display: flex
	justify-content: space-between
	border: 1px solid
	@media (max-width: 767.98px)
		flex-direction: column
		border-radius: 20px
	a
		color: #000
		min-width: 33.33%
		white-space: nowrap
		-webkit-user-select: none
		user-select: none
		box-sizing: border-box
		border-radius: 70px
		font-family: Nunito, sans-serif
		font-size: 16px
		font-style: normal
		font-weight: 700
		line-height: 1
		letter-spacing: 0em
		text-align: center
		height: 42px
		cursor: pointer
		text-decoration: none
		display: inline-flex
		align-items: center
		align-content: center
		justify-content: center
		padding: 0px 10px
		&.active
			background: radial-gradient(60.28% 173.9% at 0% -19.55%, #00CC9D 0%, #009D9A 100%)
			color: #fff
			.main-ksiegowosc-online &
				background:  radial-gradient(60.28% 173.9% at 0% -19.55%, #0085CF 0%, #0E366C 54.09%, #131C4C 100%)

.wrapper-price-list-description
	&--pretitle
		font-style: italic
		font-weight: 300
		font-size: 14px
		line-height: 19px
		text-align: center
		color: #7B7B7B
	&--title
		font-style: normal
		font-weight: 300
		font-size: 40px
		line-height: 55px
		text-align: center
		margin-bottom: 1rem
	&--content
		font-style: normal
		font-weight: 300
		font-size: 14px
		line-height: 19px
		text-align: center
		max-width: 620px
